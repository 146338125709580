.stickymenu {
    position:fixed;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1vw 15px 2vw 15px;
    z-index: 1000;
    text-align: center;
    max-width: 800px;
  }
  .confirmButton{
    background-color: transparent;
    border: none;
    color: black;
    font-weight: 600;
    padding: 0;
    margin-top:20px
  }
  .cashoutbtn{
    background-color: #3E28E8;
    color:white;
    border-radius: 5px;
    border: none;
    margin-top:20px
  }
  #confirm{
    background-color: #3E28E8;
    border: none;
    color:white;
    border-radius: 5px;
    padding:  10px 70px;
    font-size: 20px;
  }  
  #cancel{
    background-color: #EFEFEE;
    border: none;
    color:black;
    border-radius: 5px;
    padding:  10px 70px;
    font-size: 20px;
  }
  #Boxis{
    color: black;
    font-size: 20px;
    font-weight: bolder;
  }
  @media only screen and (min-width:1000px){
  .inputAccount{
    margin: 10px;
  }
  } 