.popup {
  z-index: 1000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
}

.submitbutton {
  display: block;
  background-color: rgb(62, 40, 232);
  color: white;
  border: none;
  border-radius: 5px;
  margin: 0px 0px 40px 0px;
  padding: 10px 30px;
}

.closebutton {
  margin: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color:white
}
.messageName{
  color:white;
  font-size: 16px;
  font-weight: 400;
}
.message{
  color:white;
  font-size: 13px;
}
.popupTop{
  width:346px;
  padding: 15px;
  border-radius: 20px;
  display: flex;
  position: relative;
  background-color: #333942  ;
}
.popupBottom{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333942;
}

.popupinner {
  height: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  padding: 20px;
  padding-top: 80px;
  text-align: center;
  outline-width: 3px;
  outline-color: black;
  border-color: black;
}
#wonImg{
  text-align: center;
  position: relative;
  object-fit: contain;
  height: 60%;
}
#description{
  color: black;
  font-size: 2vh;
}
#probability{
  color: rgba(0, 0, 0, 0.3);
  margin: 3% 6%;
  font-size: 1vh;
  font-stretch: extra-expanded;
}
#price{
  background-color: #FFC658;
  padding: 1.5% 3%;
  margin: 3%;
  font-size: 2vh;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  width: fit-content;
}
#name{
  width: 100%;
  text-align: center;
  font-size: 2vw;
  font-weight: 700;
  margin: 40px 0px 0px 0px;
  color: black;
}
#info{
  width: 100%;
  text-align: center;
  font-size: 2vw;
  font-weight: 700;
  margin: 0px 0px 40px 0px;
  color: black;
}
@media only screen and (max-aspect-ratio:3/4) {
  .popup{
    justify-content: center;
  }
  .popupinner{
    justify-content: end;
  }
  #name,#info{
    font-size: 20px;
  }
}