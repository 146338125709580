.popup {
  z-index: 3000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

#erroraccountexists {
  text-align: start;
  padding: 5px;
  background-color: #EFEFEE;
  border-left-width: 2px;
  border-left-color: #E05E3D;
  border-left-style: solid;
  margin-bottom: 30px;
}

.toggle-password-button {
  position: absolute;
}

.submit-button {
  display: block;
  background-color: rgb(62, 40, 232);
  color: white;
  width: 97%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.choose-login-signup {
  margin-bottom: 15px;
  border-radius: 20px;
  background-color: white;
  text-align: start;
}

.popup-head {
  display: block;
  background-color: white;
  text-align: center;
}

.popup-inner {
  
  width:340px;
  display: block;
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  outline-width: 3px;
  outline-color: black;
  border-color: black;
}


#termsandconditionsagreement {
  display: block;
  color: grey;
  font-size: 10px;
}

.login-option {
  width: auto;
  display: inline;
  position: relative;
  text-align: center;
  margin: 5px;
  padding: 0px;
  border: none;
}


.password-input {
  margin-bottom: 0px;
}

