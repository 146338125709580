/* src/CaseScroll.css */
.case-scroll {
  overflow: hidden;
  width: 80vw;
  margin: 20px;
  position: relative;
}
.BoxPageItemPriceText{
  font-size: 16px;
}
.tryforfreebtn {
  background-color: transparent;
  border-radius: 50px;
  border:2px solid #3E28E8;
  color: #3E28E8;
}

.BoxPageItemsInside {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.cratenameopen {
  background-color: white;
  display: flex;
  align-items: start;
  margin-top: 5vh;
  padding: 5vh 0;
}

#purple-arrows {
  position: absolute;
  z-index: 5;
  top: 0;
  justify-self: center;
  bottom: 0;
}

.white-bg {
  padding: 60px 0px;
  background-color: white;
  z-index: 1;
}

.gray-bg {
  padding: 60px 0px;
  background-color: #D9D9D9;
  z-index: 1;
}

#featuredluxuryboxes {
  display: inline-block;
  width: 100%;
}

#containerroulette {
  background-color: #EFEFEE;
  display: flex;
  flex-wrap: wrap;
}

.boxPageStickyOpen {
  background-color: white;
  border-radius: 5px;
  border: none;
  color: black;
  font-size: 16px;
}

#boxOpenPageSpecialContainer1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#boxOpenPageStickyImage {
  margin: 0px 30px;
}

#boxPageStickyStartButton {
  position: fixed;
  z-index: 1000;
  height: 90px;
  top: 60;
  background-color: #3E28E8;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
}

#BoxPageFeaturedLuxuryBoxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.BoxPageBox {

  width: 30%;
  display: inline-block;
  background-color: white;
  margin: 1% 1% 1% 1%;
}

.case-scroll-container {
  display: flex;
  z-index: 2;
}

.itemImg {
  object-fit: contain;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.item {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14vw;
  width: 14vw;
  margin: 0px 1vw;
  text-align: center;
  padding: 1vw;
  box-sizing: border-box;
  border-radius: 1vw;
}

.rewardsimg {
  object-fit: contain;
  height: 100%;
  width: 100%;
  z-index: 10;
  max-height: 10vw;
}

.sitem {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 20vw;
  max-height: 20vw;
  margin: 1.65vw 1.65vw;
  text-align: center;
  padding: 1vw;
  box-sizing: border-box;
  background-color: white;
}

.products-inside {
  margin: 10px 0px;
  color: black;
  font-size: 28px;
  font-weight: bold;
}

.item.red {
  background-color: red;
}

.item.pink {
  background-color: pink;
}

.item.gray {
  background-color: gray;
}

.item.yellow {
  background-color: yellow;
}

.boxpagename {
  font-size: 3vw;
  color: black;
  margin: 2vh 0;
}

.boxpagedescription {
  -webkit-box-orient: vertical;
  -webkit-line-clamp:2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  color:#3E28E8;
  font-size: 2vw;
  margin: 3vh 0 0 0;
}
.expanded{
  display: block;
}
.openforbutton {
  background-color: #3E28E8;
  border-radius: 50px;
  border: none;
  color: white;
}


#BoxopenpagePrompt {
  color: black;
  fontSize: small;
}

.result {
  margin-top: 20px;

}

.result-item.red {
  color: red;
}

.result-item.pink {
  color: pink;
}

.result-item.gray {
  color: gray;
}

.result-item.yellow {
  color: darkgoldenrod;
}

.fontfix {
  font-size: 1vw;
  color: black;
}

.boxicon {
  width: 20vw;
  aspect-ratio: auto;
}

.infobutton {
  width: 25px;
  height: 25px;
}

.box {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  margin: 1% 1% 1% 1%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mainBoxImg {
  position: relative;
}

.boxName {
  font-size: 16px;
  color: black;
  padding: 10px 20px;
}

.boxinfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
  width: 100%;
}
.mobilehide{
  display: inline;
}
.mobileshow{
  display: none;
}
@media only screen and (max-width: 800px) {
  #boxOpenPageSpecialContainer1 {
  flex-direction: column;
  align-items: start  ;
}
  .case-scroll {
    width: 100vw;
    margin: 0px;
  }
  .mobilehide{
    display: none;
  }
  .mobileshow{
    display: flex;
  }
  .openforbutton,.tryforfreebtn{
    width:160px;
    height:35px;
    font-size: 13px;
    justify-content: center;
    align-items: center;
  }
  .box {
    width: 47%;
    background-color: white;
    margin: 1% 1% 1% 1%;
  }

  .item,
  .sitem {
    width: 18vw;
    height: 18vw;
    margin: 0px 1vw;
  }

  .gray-bg {
    padding: 7vw 0vw;
  }

  .white-bg {
    padding: 5vw 0vw;
  }

  .fontfix {
    font-size: 2vw;
  }

  .infobutton {}

  .products-inside {
    font-size: 18px;
  }

  #BoxopenpagePrompt {
    font-size: xx-small;
  }
}

@media only screen and (max-width: 600px) {
  
  .boxpagename {
    font-size: 22px;
    font-weight: bold;
  }

  .BoxPageItemPriceText{
    font-size: 12px;
  }
  .boxicon {
    width: 40vw;
  }

  .boxpagedescription {
    font-size: 14px;
  }
}
@media only screen and (max-width: 400px) {
  #BoxopenpagePrompt{
    display: none;
  }
}