.container {
  position: relative;
  display: flex;
  margin: 0;
  padding: 1vw 0vw 1vw 1vw;
  flex-wrap: wrap;
}

.leftbody {
  padding: 10px;
  width: 75vw;
  margin: 0px 3%;
  background-color: white;
}
.inputAccount{
  width: 32%;
  margin:0.5%;
  padding: 1vw 0.5vw;
}
.accounttop{
  display: flex;
  justify-content: space-between;
}
.fatheader {
  margin: 2% 0;
  font-weight: bolder;
  color: black;
}
#editpersonalbtn,#editshipaddress{
  margin:0.5%;
  padding: 0.7vw 1vw;
  border-radius: 50px;
  border: none;
  background-color: #3E28E8;
  color: white;
}
#shippingprompt{
  color:black;
  font-size: small;
  width:50%
}
#back{
  color: black;
  text-decoration: none;
}
#personaldata{
  position: relative;
  margin-bottom: 25px;
}
#chooseMenu{
  width: 50%;
}
/* #cashoutbtn{
  color: lightgray;
} */
#accountbtn,#historybtn,#cashoutbtn{
  width: 30%;
  margin:1%;
  padding:10px 0.5%;
  border: none;
  border-radius: 50px;
  font-weight: 700;
}
#accountbtn{
  background-color: #3E28E8;
  color: white;
}
@media only screen and (max-width: 800px) {
  .leftbody{
    width: 100%;
  }
  #back{
    display: none;
  }
  .box {
    width: 47%;
    display: inline-block;
    background-color: white;
    margin: 1% 1% 1% 1%;
  }
  #chooseMenu{
    width: 100%;
  }
}
@media only screen and (max-width: 650px) {
  .fatheader{
    font-size: 3vw;
  }
}
@media only screen and (max-width: 500px) {
  .inputAccount{
    width: 47%;
    margin:1.5%;
    padding: 1vw 0.5%;
  }
  #shippingprompt{
    padding: 0;
    color:black;
    font-size: small;
    width:100%
  }
}@media only screen and (max-width: 400px) {
  .inputAccount{
    width: 97%;
    margin:1.5%;
    padding: 3vw 2%;
  }
  #editpersonalbtn,#editshipaddress{
    margin:1.5%;
    padding: 3vw 2%;
    border-radius: 5px;
    border: none;
    background-color: #3E28E8;
    color: white;
  }
}