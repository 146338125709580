*,
*::before,
*::after {
    box-sizing: border-box;
      cursor: pointer;
}
    
    body {
    height: 100%;
    overflow: auto;
    }
/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* Remove default padding */
ul, ol, menu, li {
    padding: 0;
}

/* Remove list styles */
ul,
ol {
    list-style: none;
}

/* Remove other default styles */
body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    background-color: #ffffff;
    color: #333333;
}
